<template>

    <v-card width="100%">
      <notifications
      group="public"
      position="bottom center"
      :style="{ marginBottom: '2px' }"
    />

      <h1 class="text-center">
        <base-arrow-back-icon @click="$router.go(-1)">{{ this.rtl ? rightArrowIcon : leftArrowIcon }}</base-arrow-back-icon>
        {{ $t("coursesList.courseInfo") }}
      </h1>
      
      <v-container>
        <v-form lazy-validation ref="form" v-on:submit.prevent>
          <v-row justify="center">
            <v-col cols="12" md="6">
              <v-text-field v-model="form.title" :label="$t('adminCourses.courseTitle')" :rules="[(v) => !!v || this.$t('required.field')]"></v-text-field>

              <!-- <v-select v-model="selectedInstrucors" :items="mapSelectData(getInstructors, 'instructor_name')" :label="$t('instructors.instructorName')" :rules="arrayRequireOneRules" class="mt-5" multiple></v-select> -->

              <v-select :items="mapSelectData(getInstructors, 'instructor_name')" v-model="instructorName" :rules="[(v) => !!v || this.$t('required.field')]" :label="$t('instructors.instructorName')"></v-select>

              <v-radio-group v-if="!this.edit" row :label="$t('adminCourses.courseLanguage') + ': '" v-model="form.default_lang" :rules="[(v) => !!v || this.$t('required.field')]" class="mb-6">
                <v-radio class="mt-3" v-for="(lang, index) in languages" :key="index" :label="lang.label" :value="lang.value"></v-radio>
              </v-radio-group>

              <v-select v-model="categoryName" :items="mapSelectData(getCategories, 'category_title')" :label="$t('adminCourses.categoryTitle')" :rules="[(v) => !!v || this.$t('required.field')]"></v-select>

              <v-text-field type="number" v-model="form.price" :label="$t('adminCourses.price')" :rules="[(v) => !!v || this.$t('required.field')]"></v-text-field>

              <v-select v-model="selectedTrainingPaths" :items="mapSelectData(getTrainingPaths, 'title')" :label="$t('learningPaths')" :rules="arrayRequireOneRules" class="mt-5" multiple></v-select>

              <v-file-input type="file" accept="image/*" :label="$t('image')" :prepend-icon="cameraIcon" v-model="newThumbnail"></v-file-input>

              <v-img :src="getImg" max-width="350" max-height="400"></v-img>
            </v-col>

            <v-col cols="12" md="6">
              <v-row justify="center">
                <v-col cols="12" md="12">
                  <div class="d-flex flex-column align-center">
                    <v-textarea class="w-100" v-model="form.targets" :label="$t('newAdminCourses.targets')"></v-textarea>

                    <v-textarea class="w-100" v-model="form.requirements" :label="$t('newAdminCourses.requirements')"></v-textarea>

                    <v-textarea class="w-100" v-model="form.description" :label="$t('newAdminCourses.description')"></v-textarea>

                    <v-radio-group class="ma-0 mt-5" row :label="this.$t('adminCourses.type')" v-model="form.type" :rules="[(v) => !!v || this.$t('required.field')]">
                      <v-radio v-for="(type, index) in types" :key="index" :label="type.label" :value="type.value"></v-radio>
                    </v-radio-group>
                  </div>
                </v-col>

                <!-- <v-col cols="12" md="6">
                  <v-text-field v-model="form.ar_title" :label="$t('newAdminCourses.ar_title')"></v-text-field>
                  
                  <v-textarea class="w-100" v-model="form.ar_targets" :label="$t('newAdminCourses.ar_targets')"></v-textarea>

                    <v-textarea class="w-100" v-model="form.ar_requirements" :label="$t('newAdminCourses.ar_requirements')"></v-textarea>

                    <v-textarea class="w-100" v-model="form.ar_description" :label="$t('newAdminCourses.ar_description')"></v-textarea>
                </v-col> -->

              </v-row>

              <v-row justify="center">
                <div class="w-100" v-if="form.type == 'offline' && this.$route.params.id > 0">
                  <OfflineForm v-for="(course, index) in this.getOfflineData.offlineCourses" :key="course.id" :data="course" :courseId="form.course_id" :index="index" :isView="false" :languages="languages" @close="$emit('handleClose')" @prepare="prepareOffline" @cancel="cancelNewOffline"/>
                  <base-button
                    class="text-large mb-3"
                    :options="{ block: true, isLoading: btnLoading, color: this.darkColor }"
                    @click="addNewCourse()"
                  >
                    {{ this.$t("adminCourses.newOffline") }}
                  </base-button>
                </div>
              </v-row>

            </v-col>

            
          </v-row>


          <v-col cols="12" lg="12" sm="12">
            <base-button class="text-large" :options="{ block: true, isLoading: btnLoading, color: this.BColor }" @click="save()">
              {{ this.$t("form.save") }}
            </base-button>
          </v-col>

          <v-col>
            <base-button class="text-large" :options="{ block: true, color: this.YColor }" @click="$router.go(-1)">
              {{ this.$t("form.cancel") }}
            </base-button>
          </v-col>
        </v-form>
      </v-container>
      
    </v-card>

</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import BaseButton from "../../../../../core/Base/Buttons/BaseButton";
import BaseArrowBackIcon from "../../../../../core/Base/Arrows/BaseArrowBackIcon";
import OfflineForm from "./OfflineForm"

export default {

  mame: 'editCourses',

  components: { 
    BaseButton,
    OfflineForm,
    BaseArrowBackIcon
  },

  data() {
    return {
      offlineCourses: [],
      isCreate: false, // to send it the offline course to determine if edit or create
      form: {
      },
      offlineForm: {}, // the offline form data if the type is offline
      offlineValidation: null, // the offline form validation
      instructorName: "",
      categoryName: "",
      selectedTrainingPaths: [],
      selectedInstrucors: [], // until the backend is fixed only one instructor
      
      dialog: true,
      newThumbnail: null,
      languages: [
        { label: this.$t("langs.arabic"), value: "ar" },
        { label: this.$t("langs.english"), value: "en" },
        // { label: this.$t("langs.mix"), value: "mix" },
      ],
      types: [ 
        { label: this.$t('adminCourses.online'), value: 'online'},
        { label: this.$t('adminCourses.offline'), value: 'offline'}
      ],
      btnLoading: false, // for the save button
      edit: false,
    };
  },

  created() {
    this.FetchTrainingPaths();
    this.fetchInstructors().then(() => {
      console.log(this.getInstructors)
    });
    this.FetchCategory();
    this.init();
  },  

  methods: {
    ...mapActions("Courses", ["addCourse", "updateCourse", "addOfflineCourse", "updateOfflineCourse", "fetchOfflineData", "FetchTrainingPaths",
      "fetchInstructors", "FetchCategory", "translateCourse"]),

    init () {
      if (this.$route.params.id > 0) {  // if it is edit not create
      
      this.fetchOfflineData(this.$route.params.id).then(() => {
        this.form = this.getOfflineData;
        this.selectedTrainingPaths = this.getOfflineData.trainingPathCourses.map(el => {
          return el.trainingPath.title;
        })
        this.selectedInstrucors = this.getOfflineData.courseInstructors.map(el => {
          return el.instructor.firstName + ' ' + el.instructor.lastName;
        })
        this.categoryName = this.getOfflineData.category.title;
        this.instructorName = this.getOfflineData.courseInstructors[0].instructor.firstName + ' ' + this.getOfflineData.courseInstructors[0].instructor.lastName
        console.log('object')
        console.log(this.form)
      })
      }
    },

    prepareOffline (payload) {
      if (payload.add) {
        if (this.getOfflineData.offlineCourses.length <= 1) {
          this.save();
        }
      }
      this.fetchOfflineData(this.$route.params.id)
    },

    addNewCourse () {
      this.isCreate = true;
      let newCourse = { courseId: this.$route.params.id.toString(), address: '', capacity: 0, description: '', start_date: null, end_date: null };
      this.getOfflineData.offlineCourses.push(newCourse)
    },

    /**
     * delete the new offline data that has not been uploaded yet
     */
    cancelNewOffline (payload) {
      this.getOfflineData.offlineCourses.splice(payload.index, 1);
    },

    mapSelectData(arr, title) {
      return arr.map((el) => el[title]);
    },

    save() {
      console.log(this.form)
      if (!this.$refs.form.validate()) return;
      this.form.instructor_id = this.calcInstructorId;
      console.log(this.form.instructor_id)
      this.form.category_id = this.calcCategoryId;
      this.form.training_path_Ids = this.calcTrainingPaths;
      this.btnLoading = true;
      if (this.$route.params.id > 0) {
        // update and create have different methods in dealing with names that's why I added the following two lines
        this.form.firstName = this.instructorName.split(' ')[0];
        this.form.lastName = this.instructorName.split(' ')[this.instructorName.length - 1]
        console.log(this.form)
        
        this.prepareCourse();
        this.form.course_id = this.$route.params.id;
        if (!this.newThumbnail) {  // because the backend does not allow string values
          this.form.thumbnail = undefined;
        }
        this.updateCourse(this.form).then(() => {
          // this.translateCourse(this.form).then(() => {
          //   })
            this.init();
            this.btnLoading = false;
        });
      } else {
        this.prepareCourse();
        this.addCourse(this.form).then((res) => {
          this.btnLoading = false;
          this.$router.go(-1);
          // this.form.course_id = res.data.response.course_id;
          // console.log('test')
          // console.log(this.arabicInfo)
          // this.translateCourse(this.form).then(() => {
          //   })
        });
      }
    },

    prepareCourse () {
      this.form.language = this.form.default_lang;
      if (this.newThumbnail) {
        this.form.thumbnail = this.newThumbnail
      }
    },

    close() {
      this.form= {
        course_type: 'online', // default
      }
      this.$emit("handleClose");
      this.$refs.form.resetValidation();
    },
  },

  computed: {
    ...mapGetters("Courses", ["getOfflineData", "getInstructors", "getCategories", "getTrainingPaths"]),
    ...mapState(["rtl"]), // to decide the direction of the arrow
    getImg() {
      if (this.newThumbnail) {
        // return `https://bact-back.usol.ca${this.form.thumbnail}`;

        return URL.createObjectURL(this.newThumbnail)
      } else {
        return `https://bact-back.usol.ca${this.form.thumbnail}`;
      }
    },

    calcInstructorId() {
      let instructor = null;
      instructor = this.getInstructors.find((el) => {
        return el.instructor_name === this.instructorName;
      });
      return instructor.instructor_id;
    },

    calcCategoryId() {
      let category = null;
      category = this.getCategories.find((el) => {
        return el.category_title === this.categoryName;
      });
      console.log(category);
      return category.category_id;
    },

    calcTrainingPaths() {
      let trainingPathsIds = [];
      let tempPath = {};
      this.selectedTrainingPaths.forEach((path) => {
        let tempPath = this.getTrainingPaths.find((el) => {
          return el.title === path;
        });
        if (tempPath) {
          trainingPathsIds.push(tempPath.id);
        }
      });
      return trainingPathsIds;
    },

    arrayRequireOneRules() {
      return [
        (v) => !!v || this.$t('required.field'),
        (v) => v.length > 0 || this.$t('required.array'),
      ];
    },
  },
};
</script>
