<template>
  <v-dialog persistent max-width="600px" v-model="isOpen">
    <v-card class="confirm-delete">
      <v-card-title class="headline text-center">{{ $t('deleteModal.confirmMessage') }}</v-card-title>
      <v-card-actions>
        <v-row class="d-flex flex-column flex-md-row justify-space-around align-center my-3">
          <base-button
            class="text-large my-2"
            :options="{ block: false, color: RColor }"
            @click="deleteMethod()"
          >
            {{ $t('deleteModal.yes') }}
          </base-button>
          <base-button
            class="text-large my-2"
            :options="{ block: false }"
            @click="close()"
          >
            {{ $t('deleteModal.no') }}
          </base-button>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseButton from "../../../../../core/Base/Buttons/BaseButton";

export default {
  name: "ConfirmDelete",
  components: { BaseButton },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    id: Number,
    deleteType: String
  },

  methods: {
    deleteMethod() {
      if (this.deleteType === 'answer') {
        this.$emit('deleteAnswer', { id: this.id })
      } else if (this.deleteType === 'question') {
        this.$emit('deleteQuestion', { id: this.id })
      } else {
        this.$emit("handelRequest", {id:this.id,method:'delete'});
      }
    },
    close() {
      this.$emit("handleCancel");
    },
  },
};
</script>

<style scoped>
.text-large {
  font-size: 18px !important;
}

.confirm-delete {
  overflow: hidden !important;
}
button {
  width: 200px !important;
}

.confirm-delete {
  overflow: hidden !important;
}
</style>
